import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from '../Shared/BackgroundImage/BackgroundImage'
import Container from '../Shared/Container/Container'
import Title from '../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import BackgroundDesktop from '../../images/linker-orange-desktop.svg'
import BackgroundTablet from '../../images/linker-orange-tablet.svg'
import BackgroundMobile from '../../images/linker-orange-mobile.svg'

const Section = styled('div')`
  padding-top: 125px;
  padding-bottom: 125px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 80px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 64px;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 172px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 110px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 106px;
  }
`

const StyledTitle = styled(Title)`
  max-width: 544px;
  color: #fff;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 40px;
    line-height: 48px;
    margin-right: 0;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  max-width: 587px;
  display: flex;
  margin-left: auto;
  margin-top: 350px;
  font-family: ${props => props.theme.fonts.secondaryBook};
  color: #fff;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
    max-width: 351px;
    margin-top: 200px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
    margin-top: 110px;
    margin-left: 0;
  }
`

export default function IntroBlock() {
  return (
    <Section>
      <StyledBackgroundImage
        desktop={BackgroundDesktop}
        tablet={BackgroundTablet}
        mobile={BackgroundMobile}
      />
      <Container>
        <StyledTitle>What we do</StyledTitle>
        <Text>
          We work with companies who have big ambitions and are eager to lead
          change in their respective industries by helping them solve the most
          complex business challenges with design and technology. We have helped
          forward-thinking businesses launch new digital products, improve
          existing technology, and advise on product strategy.
        </Text>
      </Container>
    </Section>
  )
}

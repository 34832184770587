import React from 'react'
import styled from '@emotion/styled'

const BlackBanner = styled('div')`
  background: black;
  padding: 70px 10% 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 60px 74px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 60px 10%;
  }
`

export default BlackBanner

import React from 'react'
import styled from '@emotion/styled'
import TransparentCTA from '../Buttons/TransparentCTA'
import ResponsiveImage from '../Shared/ResponsiveImage/ResponsiveImage'
import Title from '../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import Container from '../Shared/Container/Container'
import {
  backgroundImages,
  howWereDifferentImages,
} from './data/howWereDifferent'
import gtm from '../../util/gtmEvent'

const Section = styled('div')`
    background-image: url('${props => props.backgroundImage}');
    padding-top: 127px;
    padding-bottom: 138px;
    background-size: cover;

    @media screen and (max-width: ${props => props.theme.screenWidth.large}) { 
        background-image: url('${props => props.backgroundImageTablet}');
        padding-top: 81px;
        padding-bottom: 50px;
    }

    // Retina media query for image
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${props =>
      props.theme.screenWidth.large}) {
        background-image: url('${props => props.backgroundImageTablet2x}');
    }

    @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
        padding-top: 60px;
        padding-bottom: 30px;
        background-image: url('${props => props.backgroundImageMobile}');
    }

    // Retina media query for image
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: ${props =>
      props.theme.screenWidth.small}) {
        background-image: url('${props => props.backgroundImageMobile2x}');
    }
`

const Image = styled(ResponsiveImage)`
  position: relative;

  img {
    filter: drop-shadow(5px 15px 6.44017px rgba(0, 0, 0, 0.15));
    width: 100%;

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
    }
  }
`

const StyledTitle = styled(Title)`
  margin-bottom: 66px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-bottom: 62px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 49px;
    font-size: 40px;
    line-height: 48px;
  }
`

const Content = styled('div')`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column-reverse;
  }
`

const ImageContainer = styled('div')`
  align-self: center;
  flex-basis: 50%;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 30px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 20px;
  }
`

const ContentContainer = styled('div')`
  flex-basis: 50%;
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  font-family: ${props => props.theme.fonts.secondaryBook};
  max-width: 473px;
  float: right;
  margin-left: 50px;
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
    max-width: 351px;
    margin-left: 0;
  }
`

const CTAContainer = styled('div')`
  max-width: 150px;
  margin-top: 55px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 132px;
    margin-top: 36px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 34px;
  }
`

const StyledCTA = styled(TransparentCTA)`
  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    line-height: 41px;
    height: 41px;
  }

  span {
    font-family: ${props => props.theme.fonts.primaryMedium};

    @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export default function ContentBlockWithImages() {
  return (
    <Section
      backgroundImage={backgroundImages.desktop}
      backgroundImageTablet={backgroundImages.tablet}
      backgroundImageTablet2x={backgroundImages.tablet2x}
      backgroundImageMobile={backgroundImages.mobile}
      backgroundImageMobile2x={backgroundImages.mobile2x}
    >
      <Container>
        <Content>
          <ImageContainer>
            <Image image={howWereDifferentImages} />
          </ImageContainer>
          <ContentContainer>
            <StyledTitle>How we’re different</StyledTitle>
            <Text>
              We focus on end-to-end product development, from research &
              strategy, to ideation & design, engineering, and product
              marketing. We work collaboratively in cross-functional teams,
              bringing the expertise of each team member to every project. All
              our work is done in-house.
              <CTAContainer>
                <StyledCTA
                  blackBorder
                  onClick={() =>
                    gtm(
                      'CTA Click',
                      'CTA Click',
                      'One Eleven',
                      'Get in Touch - How we are'
                    )
                  }
                />
              </CTAContainer>
            </Text>
          </ContentContainer>
        </Content>
      </Container>
    </Section>
  )
}

import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'

const Image = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      freshbooksImage: file(
        relativePath: { eq: "one-eleven/service-blocks/freshbookers@2x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1176, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sessionzImage: file(
        relativePath: { eq: "one-eleven/service-blocks/sessionz@2x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1177, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      omstarsImage: file(
        relativePath: { eq: "one-eleven/service-blocks/omstars@2x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1176, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      leagueImage: file(
        relativePath: { eq: "one-eleven/service-blocks/league@2x.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1176, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data[name].childImageSharp.fluid} alt={alt} />
}

export const serviceBlocks = [
  {
    title: 'Build something new',
    paragraph:
      'Building a brand new digital product from scratch can be a daunting task. Whether your digital product will be used by a few people or by millions of users across the globe, Crowdlinker has the strategy, design, development, and marketing capabilities to make your next product into an industry leader.',
    example: {
      title: 'Freshbooks',
      description:
        'Freshbooks is an invoicing platform helping small to medium-sized businesses with their accounting. The Freshbooks SEO team partnered with Crowdlinker to create an online invoice generator found on FreeInvoiceCreator.com. The two teams worked together on strategy, product design, and development to create a tool that 5x their SEO leads in a 6-month period.',
      button: {
        text: 'View Case Study',
        link:
          'https://crowdlinker-website-assets.s3.amazonaws.com/case-studies/Freshbooks+Case+Study.pdf',
      },
      image: <Image name="freshbooksImage" alt="Freshbooks Case Study" />,
    },
    background: '#1F81E6',
  },
  {
    title: 'Improve existing products',
    paragraph:
      'Picking the right partner to improve your existing digital products is a significant decision. Companies big and small have trusted Crowdlinker in the past because of our keen ability to balance business goals, customer needs, and technical constraints. Our fresh perspective has helped transform digital products into category-leaders.',
    example: {
      title: 'League',
      description:
        "League is creating a health benefits experience platform for the world's most forward-thinking organizations. League partnered with Crowdlinker to improve their marketing teams' functionality on their main site. By moving their web app onto a sub-domain and rebuilding the marketing site on WordPress, League saw a 400% improvement in their publishing time.",
      button: {
        text: 'View Case Study',
        link:
          'https://crowdlinker-website-assets.s3.amazonaws.com/case-studies/League+Case+Study.pdf',
      },
      image: <Image name="leagueImage" alt="League Case Study" />,
    },
    background: '#DE659C',
  },
  {
    title: 'Launch a new company',
    paragraph:
      'Crowdlinker started off as a SaaS company that raised over $1 million in funding to build out a sophisticated line-up of social media automation products. After pivoting into a full-service digital product studio, we continue to leverage our experience from our SaaS days to help founders take their product vision from concept to production.',
    example: {
      title: 'Sessionz',
      description:
        'Sessionz is a mobile marketplace app that helps people find and book one-on-one sessions with teachers, instructors, and other everyday experts. Crowdlinker partnered with Sessionz founder (Andres Pareja) to launch an MVP of their iOS app that had the ability for users to search, book sessions, and pay for sessions, as well as, for teachers to list a session, manage their availability, accept session requests, and get paid.',
      button: {
        text: 'View Case Study',
        link:
          'https://crowdlinker-website-assets.s3.amazonaws.com/case-studies/Sessionz+Case+Study.pdf',
      },
      image: <Image name="sessionzImage" alt="Sessionz Case Study" />,
    },
    background: '#38215D',
  },
  {
    title: 'Product consulting',
    paragraph:
      'Every forward-thinking company has the ambition to keep growing and to scale out their solution in order to help more people. Crowdlinker’s product consultancy acts as a catalyst for those companies to reach their desired goals faster. How much would your business benefit from a new perspective coming from experts who have built products used by millions of people daily?',
    example: {
      title: 'Omstars',
      description:
        'Omstars partnered with Crowdlinker to help them become the “Netflix” for Yoga. Their challenges ranged from bugs in their legacy codebase to little indexability on Google search. Crowdlinker guided Omstars towards a scalable infrastructure and improved site performance which nearly doubled their customer base from 3,000 to 5,000 monthly active users, in addition to doubling their monthly recurring revenue.',
      button: {
        text: 'View Case Study',
        link:
          'https://crowdlinker-website-assets.s3.amazonaws.com/case-studies/Omstars+Case+Study.pdf',
      },
      image: <Image name="omstarsImage" alt="Omstars Case Study" />,
    },
    background: '#132B3E',
  },
]

import React from 'react'
import styled from '@emotion/styled'
import ArrowIcon from '../Icons/Arrow'
import { CTA } from '../../Buttons/TransparentCTA'
import gtm from '../../../util/gtmEvent'

const StyledBlockContainer = styled('section')`
  width: 100%;
  background: ${props =>
    props.background ? props.background : props.theme.colors.black};
`

const StyledBlockWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1280px;
  flex-direction: row;
  padding: 120px 80px 120px 100px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 80px 8.5% 80px 8.5%;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 60px 17% 60px 17%;
  }
`

const StyledBlockTextContainer = styled('div')`
  order: 1;
`

const StyledBlockHeading = styled('h2')`
  font-size: 48px;
  line-height: 56px;
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.primaryMedium};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 24px;
    line-height: 28px;
  }
`

const StyledBlockParagraph = styled('p')`
  margin: 0;
  font-size: 38px;
  line-height: 42px;
  padding-top: 60px;
  padding-right: 80px;
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.secondary};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    max-width: 510px;
    line-height: 27px;
    padding-top: 45px;
    padding-right: 0px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
    max-width: 100%;
    line-height: 22px;
    padding-top: 40px;
  }
`

const StyledExampleContainer = styled('div')`
  order: 2;
  width: 100%;
  display: flex;
  padding-top: 60px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column;
    padding-top: 40px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 40px;
  }
`

const StyledExampleTextContainer = styled('div')`
  order: 1;
  width: 45%;
  display: flex;
  padding-right: 120px;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    order: 2;
    width: 100%;
    padding-top: 40px;
    padding-right: 0px;
    flex-direction: row;
    align-items: flex-end;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 30px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const StyledExampleTextWrapper = styled('div')`
  width: 100%;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 70%;
    padding-right: 80px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    padding-right: 0px;
  }
`

const StyledExampleTitle = styled('h4')`
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.sourceCode};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 34px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 16px;
    line-height: 34px;
  }
`

const StyledExampleDescription = styled('p')`
  font-size: 23px;
  line-height: 28px;
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 16px;
    line-height: 18px;
  }
`

const StyledExampleImageContainer = styled('div')`
  order: 2;
  width: 55%;

  img {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    order: 1;
    width: 80%;
    align-self: flex-end;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 114%;
    align-self: center;
  }
`

const StyledExampleButtonContainer = styled('div')`
  width: 100%;
  max-width: 255px;
  padding-top: 55px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100%;
    max-width: 190px;
    padding-top: 0px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 80%;
    padding-top: 35px;
    margin-left: -20px;
  }
`

let StyledCTA = styled(CTA)`
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    height: 42px;
  }

  span {
    display: flex;
    align-items: center;
    font-family: ${props => props.theme.fonts.primaryMedium};

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &:hover {
    span.arrowIcon > svg > path {
      fill: black;
      transition: all 0.3s ease;
    }
  }

  span.arrowIcon {
    padding-left: 15px;
  }
`
StyledCTA = StyledCTA.withComponent('a')

const trackEvent = clientName =>
  gtm(
    'Case Study Link',
    'Case Study CTA Click',
    'One Eleven',
    `${clientName} - Case Study`
  )

const ServiceExampleButton = ({ link, text, clientName }) => {
  return (
    <StyledCTA
      href={link}
      target="_blank"
      onClick={() => trackEvent(clientName)}
    >
      <span>{text}</span>
      <span className="arrowIcon">
        <ArrowIcon />
      </span>
    </StyledCTA>
  )
}

const ServiceExample = ({ title, description, button, image }) => {
  return (
    <StyledExampleContainer>
      <StyledExampleTextContainer>
        <StyledExampleTextWrapper>
          <StyledExampleTitle>{title}</StyledExampleTitle>
          <StyledExampleDescription>{description}</StyledExampleDescription>
        </StyledExampleTextWrapper>
        <StyledExampleButtonContainer>
          <ServiceExampleButton {...button} clientName={title} />
        </StyledExampleButtonContainer>
      </StyledExampleTextContainer>
      <StyledExampleImageContainer>{image}</StyledExampleImageContainer>
    </StyledExampleContainer>
  )
}

export default function ServiceBlock({
  title,
  paragraph,
  example,
  background,
}) {
  return (
    <StyledBlockContainer background={background}>
      <StyledBlockWrapper>
        <StyledBlockTextContainer>
          <StyledBlockHeading>{title}</StyledBlockHeading>
          <StyledBlockParagraph>{paragraph}</StyledBlockParagraph>
        </StyledBlockTextContainer>
        <ServiceExample {...example} />
      </StyledBlockWrapper>
    </StyledBlockContainer>
  )
}

import React from 'react'
import { theme } from '../../../styles/theme'

export default function ResponsiveImage(props) {
  const {
    className,
    image: {
      desktopImage,
      desktopImage2x,
      tabletImage,
      tabletImage2x,
      mobileImage,
      mobileImage2x,
      alt,
    },
  } = props

  return (
    <picture className={className}>
      <source
        media={`(max-width: ${theme.screenWidth.small})`}
        srcSet={`${mobileImage}, ${mobileImage2x} 2x`}
      />
      <source
        media={`(max-width: ${theme.screenWidth.large})`}
        srcSet={`${tabletImage}, ${tabletImage2x} 2x`}
      />
      <source
        media={`(min-width: 992px)`}
        srcSet={`${desktopImage}, ${desktopImage2x} 2x`}
      />
      <img src={desktopImage} alt={alt} />
    </picture>
  )
}

import React from 'react'
import HeroWithSVG from '../Shared/HeroWithSVG/HeroWithSVG'
import HeroBg from '../../images/one-eleven/hero-desktop@2x.jpg'
import HeroBgTablet from '../../images/one-eleven/hero-tablet.jpg'
import HeroBgTablet2x from '../../images/one-eleven/hero-tablet@2x.jpg'
import HeroBgMobile from '../../images/one-eleven/hero-mobile.jpg'
import HeroBgMobile2x from '../../images/one-eleven/hero-mobile@2x.jpg'

export default function HeroAnimation() {
  return (
    <HeroWithSVG
      heroBg={HeroBg}
      heroBgTablet={HeroBgTablet}
      heroBgTablet2x={HeroBgTablet2x}
      heroBgMobile={HeroBgMobile}
      heroBgMobile2x={HeroBgMobile2x}
    />
  )
}

import React from 'react'
import BlackBanner from './Black'
import Fade from 'react-reveal/Fade'
import styled from '@emotion/styled'

const StyledBlackBanner = styled(BlackBanner)`
  h1 {
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-size: 40px;
    font-weight: 500;
    color: #8fa1a8;
    margin-bottom: 10px;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 30px;
      margin-bottom: 0;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      display: none;
    }
  }
`

const NumbersContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 30px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    flex-wrap: wrap;
    margin-top: 0;
  }
`

const NumberInfo = styled('div')`
  text-align: center;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 160px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 50%;
    margin: 25px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    display: none;
    font-size: 26px;
    line-height: 1.35;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      display: block;
    }
  }
  h3 {
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-weight: 500;
    color: white;
    margin: 0;
    font-size: 75px;
    line-height: 1;
    position: relative;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 34px;
    }
    span.plusIcon {
      font-size: 50px;
      position: absolute;
      bottom: 0;
      @media (max-width: ${props => props.theme.screenWidth.large}) {
        font-size: 24px;
      }
    }
  }
  p {
    font-family: ${props => props.theme.fonts.sourceCode};
    color: #8fa1a8;
    font-size: 18px;
    margin: 10px;
    text-transform: uppercase;
    font-weight: 500;
    @media (max-width: ${props => props.theme.screenWidth.large}) {
      font-size: 16px;
    }
    @media (max-width: ${props => props.theme.screenWidth.medium}) {
      font-size: 14px;
      margin: 5px 0 0;
      padding: 0 10px;
      line-height: 1.45;
    }
  }
  &.mobile-header {
    margin: auto;
    text-align: left;

    @media (max-width: ${props => props.theme.screenWidth.medium}) {
      margin: 0 auto;
    }
  }
`

const MobileHeader = styled('div')`
  display: none;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
  }
`

export default function StatsBanner({ statsSectionTitle, statsSectionList }) {
  const statsList = statsSectionList.map((stat, i) => {
    let statNumber = stat.number
    if (stat.number.indexOf('+') !== -1) {
      statNumber = statNumber.replace('+', '')
      statNumber = (
        <React.Fragment>
          <h3>
            {statNumber}
            <span className="plusIcon">+</span>
          </h3>
        </React.Fragment>
      )
    } else {
      statNumber = <h3>{statNumber}</h3>
    }

    const delay = i * 200
    return (
      <NumberInfo key={i}>
        <Fade delay={delay} bottom distance={'100px'}>
          {statNumber}
          <p>{stat.text}</p>
        </Fade>
      </NumberInfo>
    )
  })

  return (
    <StyledBlackBanner>
      {statsSectionTitle && <h1>{statsSectionTitle}</h1>}
      <NumbersContainer>
        {statsSectionTitle && (
          <MobileHeader>
            <NumberInfo className="mobile-header">
              <h1>{statsSectionTitle}</h1>
            </NumberInfo>
          </MobileHeader>
        )}
        {statsList}
      </NumbersContainer>
    </StyledBlackBanner>
  )
}

import React from 'react'
import styled from '@emotion/styled'
import arrow from '../../../images/careers/map/speech-arrow.svg'

export const Quote = styled('h4')`
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
  color: rgba(98, 98, 98, 0.9);
  margin: 0;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 17px !important;
    margin: 8px 0;
  }
  @media (max-width: ${props => props.theme.screenWidth.xsmall}) {
    font-size: 14px !important;
  }
`
export const SpeechBubbleContainer = styled('div')`
  @media (min-width: 992px) and (max-width: 1100px) {
    transform: scale(0.77) translateX(-15%);
  }
  @media (min-width: 1101px) and (max-width: 1240px) {
    transform: scale(0.9) translateX(-10%);
  }
`
export const SpeechBubble = styled('div')`
  background-color: ${props => props.theme.colors.white};
  border-radius: 45px;
  box-shadow: -5px 9px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 0px 15px 17px 15px;
  align-items: center;
  filter: blur(4px);
  width: 580px;
  margin: 0 auto;
  transition: all 0.5s;
  cursor: ${props => (props.clickable ? 'pointer' : 'initial')};
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    width: 520px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 85% !important;
    padding: 0 30px;
  }
  &::after {
    content: url(${arrow});
    filter: drop-shadow(5px 10px 25px rgba(0, 0, 0, 0.03));
    display: block;
    position: absolute;
    bottom: 0%;
    opacity: 1;
  }
  &:nth-of-type(odd) {
    border-bottom-right-radius: 0;
    &::after {
      right: 0;
      transform: translate(-92%, 80%) scale(3, 3);
    }
  }
  &:nth-of-type(even) {
    border-bottom-left-radius: 0;
    &::after {
      left: 0;
      border-left-width: 1px;
      transform: translate(92%, 80%) scale(-3, 3);
    }
  }
  &.active {
    filter: blur(0);
    z-index: 99;
  }
  &:nth-of-type(1) {
    ${Quote} {
      font-size: 27px;
    }
    transform: scale(0.7) translate(180px, 50px);
    &.active {
      transform: scale(0.8) translate(180px, 50px);
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      transform: scale(0.7) translate(50px, 60px);
      &.active {
        transform: scale(0.8) translate(50px, 60px);
      }
    }
  }
  &:nth-of-type(2) {
    ${Quote} {
      font-size: 28px;
    }
    transform: scale(0.9) translate(0px, -40px);
    &.active {
      transform: scale(1) translate(0px, -40px);
    }
    img {
      max-width: 116px;
      margin: 0 auto;
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      transform: scale(0.9) translate(-30px, -40px);
      &.active {
        transform: scale(1) translate(-30px, -40px);
      }
    }
  }
  &:nth-of-type(3) {
    ${Quote} {
      font-size: 26px;
    }
    transform: scale(0.6) translate(180px, -220px);
    &.active {
      transform: scale(0.7) translate(140px, -160px);
    }
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      transform: scale(0.7) translate(50px, -160px);
      &.active {
        transform: scale(0.8) translate(50px, -120px);
      }
    }
  }
`
export const SpeechBubbleInner = styled('div')`
  width: 100%;
  padding: 7% 5% 15%;
  display: flex;
  height: 200px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 180px;
    flex-direction: column;
    justify-content: center;
    transform: translateX(-9px);
    padding: 8% 10% 10%;
  }
  img {
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      height: 44px;
    }
  }
`
export const QuoteContent = styled('div')`
  font-family: ${props => props.theme.fonts.secondary};
  margin-left: 20px;
`
export const Person = styled('p')`
  font-size: 18px;
  font-weight: 700;
  color: #3b3b3b;
  margin: 10px 0 0 0;
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    font-size: 14px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: none;
  }
`
export const Title = styled('p')`
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    font-size: 14px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: none;
  }
`

export default function BasicQuotes({ quotes, activeIndex, clickHandler }) {
  const switchActiveQuote = index => {
    if (clickHandler) {
      clickHandler(index)
    }
  }

  return (
    <SpeechBubbleContainer>
      {quotes.map((quote, i) => (
        <SpeechBubble
          key={i}
          clickable={!!clickHandler}
          className={i === activeIndex ? 'active' : ''}
        >
          <SpeechBubbleInner onClick={() => switchActiveQuote(i)}>
            {quote.logo && (
              <img src={quote.logo.file.url} alt={quote.logo.description} />
            )}
            <QuoteContent>
              <Quote>{quote.quote}</Quote>
              <Person>{quote.person}</Person>
              <Title>{quote.title}</Title>
            </QuoteContent>
          </SpeechBubbleInner>
        </SpeechBubble>
      ))}
    </SpeechBubbleContainer>
  )
}

import React from 'react'
import { theme } from '../../../styles/theme'

export default function BackgroundImage(props) {
  const { mobile, tablet, desktop, className } = props
  return (
    <picture className={className}>
      <source
        media={`(max-width: ${theme.screenWidth.small})`}
        srcSet={mobile}
      />
      <source
        media={`(max-width: ${theme.screenWidth.large})`}
        srcSet={tablet}
      />
      <source media={`(min-width: 992px)`} srcSet={desktop} />
      <img src={desktop} alt="" />
    </picture>
  )
}

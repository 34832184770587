import React from 'react'

const ArrowIcon = ({ size }) => {
  return (
    <svg
      width={size || '14px'}
      height={size || '14px'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="arrow_downward - material"
        d="M7 0.319999L13.68 7L7 13.68L5.82 12.46L10.48 7.82L0.319999 7.82L0.319999 6.18L10.48 6.18L5.82 1.5L7 0.319999Z"
        fill="white"
      />
    </svg>
  )
}

export default ArrowIcon

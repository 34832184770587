import React from 'react'
import LogosBanner from '../Shared/Banners/Logos'
import StatsBanner from '../Shared/Banners/Stats'
import { stats as statsSectionList } from './data/stats'
import ServiceBlock from '../Shared/Blocks/Service'
import { serviceBlocks } from './data/service-blocks'
import HeroAnimation from './HeroAnimation'
import IntroBlock from '../Shared/Blocks/IntroBlock'
import ClientTestimonials from '../Shared/ClientTestimonials/ClientTestimonials'
import WhatWeDo from './WhatWeDo'
import GradientContentBlock from './GradientContentBlock'
import Line from '../Shared/Line/Line'
import ContentBlockWithImages from './ContentBlockWithImages'
import { testimonials, quotes } from '../Shared/data/client-testimonials'

export default function SalesLandingPage(props) {
  const { name } = props
  return (
    <React.Fragment>
      <HeroAnimation />
      <IntroBlock />
      <WhatWeDo />
      {serviceBlocks.map((serviceBlock, i) => (
        <ServiceBlock {...serviceBlock} key={i} />
      ))}
      <ClientTestimonials testimonials={testimonials} quotes={quotes} />
      <LogosBanner />
      <ContentBlockWithImages />
      <StatsBanner statsSectionList={statsSectionList} />
      <GradientContentBlock name={name} />
      <Line />
    </React.Fragment>
  )
}

import React from 'react'
import styled from '@emotion/styled'

export const StyledTitle = styled('h2')`
  font-size: 72px;
  line-height: 80px;
  font-family: ${props => props.theme.fonts.primaryMedium};
  color: ${props => (props.textColor ? props.textColor : '#000')};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-right: -36px;
    line-height: 55px;
  }
`

const StyledPeriod = styled('span')`
  color: ${props => props.theme.colors.red};
`

export default function Title(props) {
  const { children, textColor, className } = props
  return (
    <StyledTitle textColor={textColor} className={className}>
      {children}
      <StyledPeriod>.</StyledPeriod>
    </StyledTitle>
  )
}

import React from 'react'
import BlackBanner from './Black'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'

const LogosContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 40px;
  max-width: 1300px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 0;
  }
`
const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 20%;
  max-width: 20%;
  padding: 22px 37px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 16px 28px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 20px 30px;

    &:last-child {
      display: none;
    }
  }
`

const CompanyLogo = styled('img')`
  max-width: 140px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 100px;
  }

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    max-width: 93px;
    max-height: 45px;
  }
`

export default function LogosBanner() {
  const data = useStaticQuery(graphql`
    query {
      contentfulAboutUs {
        companiesBannerLogos {
          title
          file {
            url
          }
        }
      }
    }
  `)

  const { companiesBannerLogos } = data.contentfulAboutUs

  const companyLogoList = companiesBannerLogos.map((logo, i) => (
    <LogoWrapper key={i}>
      <CompanyLogo src={logo.file.url} key={i} alt={logo.title} />
    </LogoWrapper>
  ))
  return (
    <BlackBanner alignItems={'center'}>
      <LogosContainer>{companyLogoList}</LogosContainer>
    </BlackBanner>
  )
}

import React from 'react'
import styled from '@emotion/styled'

const Section = styled('div')`
  padding-left: 10%;
  padding-right: 10%;
  height: 100%;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 65px;
    padding-right: 65px;
  }
`

const ContentWrapper = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100%;
`

export default function Container(props) {
  return (
    <Section>
      <ContentWrapper>{props.children}</ContentWrapper>
    </Section>
  )
}

import React from 'react'
import styled from '@emotion/styled'
import { GradientButton } from '../../Buttons'
import Title from '../TitleWithStyledPeriod/TitleWithStyledPeriod'
import Container from '../Container/Container'
import BackgroundImage from '../BackgroundImage/BackgroundImage'
import BackgroundDesktop from '../../../images/crow-outline-grey-desktop.svg'
import BackgroundTablet from '../../../images/crow-outline-grey-tablet.svg'
import BackgroundMobile from '../../../images/crow-outline-grey-mobile.svg'
import gtm from '../../../util/gtmEvent'

const Section = styled('div')`
  background-color: #fff;
  position: relative;
  padding-top: 125px;
  padding-bottom: 80px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 103px;
    padding-bottom: 100px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 153px;
  right: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 138px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 121px;
  }
`

const TitleContainer = styled('div')`
  max-width: 753px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 476px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
  }
`

const ContentContainer = styled('div')`
  position: relative;
  height: 100%;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
  }
`

const TextContainer = styled('div')`
  max-width: 587px;
  margin-top: 251px;
  margin-left: auto;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 351px;
    margin-top: 98px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
    margin-top: 80px;
    margin-left: 0;
    text-align: left;

    p {
      text-align: left;
    }
  }
`

const Button = styled(GradientButton)`
  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    line-height: 42px;
    height: 42px;
    max-width: 137px;
    padding: 0;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

const StyledTitle = styled(Title)`
  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 40px;
    line-height: 48px;
  }
`

export default function IntroBlock() {
  return (
    <Section>
      <StyledBackgroundImage
        desktop={BackgroundDesktop}
        tablet={BackgroundTablet}
        mobile={BackgroundMobile}
      />
      <Container>
        <ContentContainer>
          <TitleContainer>
            <StyledTitle>Your digital product acceleration partner</StyledTitle>
          </TitleContainer>
          <TextContainer>
            <Text>
              Crowdlinker was born from a desire to completely transform the way
              people connect with one another, leveraging innovative
              technologies and top-tier talent to create amazing digital
              products. From our own humble beginnings as a product-focused
              startup, we relate with companies who have that big idea and are
              now looking to scale it into something even bigger.
            </Text>
            <Button
              whiteBtn={true}
              gradientLeft="1px"
              gradientTop="1px"
              gradientHeight="calc(100% - 2px)"
              gradientWidth="calc(100% - 2px)"
              fontWeight="500"
              fontFamily="primaryMedium"
              padding="0 42px"
              marginTop="17px"
              onClick={() =>
                gtm(
                  'CTA Click',
                  'CTA Click',
                  'One Eleven',
                  'Get in Touch (Below hero)'
                )
              }
            />
          </TextContainer>
        </ContentContainer>
      </Container>
    </Section>
  )
}

import React from 'react'
import useMeasure from '../../../util/useMeasure'
import { Controller, Scene } from 'react-scrollmagic'

export default function BackgroundContainer({ children, backgroundColor }) {
  const [ref, { height }] = useMeasure()

  return (
    <div ref={ref}>
      <Controller>
        <Scene duration={height}>
          {(progress, event) => {
            if (event.type === 'enter') {
              document.body.style.backgroundColor = backgroundColor
            }
            return <div>{children}</div>
          }}
        </Scene>
      </Controller>
    </div>
  )
}

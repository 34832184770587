import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import BasicQuotes from '../Quotes/Basic'
import BasicTestimonials from '../Testimonials/Basic'
import TitleWithStyledPeriod from '../TitleWithStyledPeriod/TitleWithStyledPeriod'

const StyledContainer = styled('section')`
  width: 100%;
  background: ${props => props.theme.colors.white};
`
const StyledWrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  max-width: 1250px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 120px 50px 120px 50px;
  background-color: ${props => props.theme.colors.white};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column;
    padding: 60px 8.5% 60px 8.5%;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 60px 10% 60px 10%;
  }
`
const StyledTextContainer = styled('div')`
  width: 45%;
  align-self: flex-start;

  h2 {
    max-width: 500px;

    @media (max-width: ${props => props.theme.screenWidth.large}) {
      max-width: 450px;
    }

    @media (max-width: ${props => props.theme.screenWidth.small}) {
      margin-left: 25px;
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100%;
  }
`
const StyledQuotesContainer = styled('div')`
  width: 55%;
  padding-top: 130px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100%;
    padding-top: 0px;
    margin-top: -30px;
    margin-left: -30px;
    margin-bottom: -120px;
    align-self: flex-start;
  }

  @media (min-width: 880px) and (max-width: 991px) {
    margin-left: 50px;
  }

  @media (min-width: 769px) and (max-width: 879px) {
    margin-left: 0px;
  }

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    margin-left: -30px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-left: 0px;
    margin-top: -40px;
    margin-bottom: -120px;
  }
`
const StyledBasicTestimonialsContainer = styled('div')`
  position: relative;
  padding-left: 60px;
  padding-right: 50px;

  @media (min-width: 992px) and (max-width: 1200px) {
    padding-left: 80px;
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 120px;
    padding-right: 120px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 30px;
    padding-right: 0px;
  }
`

export default function ClientTestimonials({ testimonials, quotes }) {
  const timeToAnimate = 5000
  const [activeIndex, setActiveIndex] = useState(0)

  function increment() {
    const divisor = quotes.length // or testimonials.length
    setActiveIndex((activeIndex + 1) % divisor)
  }

  const switcher = index => {
    setActiveIndex(index)
  }

  useEffect(() => {
    const changeQuote = setInterval(increment, timeToAnimate)

    return () => {
      clearInterval(changeQuote)
    }
  }, [activeIndex])

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledTextContainer>
          <TitleWithStyledPeriod className="heading">
            Client Testimonials
          </TitleWithStyledPeriod>
          <StyledBasicTestimonialsContainer>
            <BasicTestimonials
              activeIndex={activeIndex}
              testimonials={testimonials}
            />
          </StyledBasicTestimonialsContainer>
        </StyledTextContainer>
        <StyledQuotesContainer>
          <BasicQuotes
            quotes={quotes}
            clickHandler={switcher}
            activeIndex={activeIndex}
          />
        </StyledQuotesContainer>
      </StyledWrapper>
    </StyledContainer>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Container from '../Shared/Container/Container'
import { StyledTitle } from '../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import { CTA } from '../Buttons/TransparentCTA'
import gtm from '../../util/gtmEvent'

const Section = styled('div')`
  padding-top: 115px;
  padding-bottom: 126px;
  position: relative;
  background: linear-gradient(156.23deg, #601947 1.97%, #315567 97.39%);

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  font-family: ${props => props.theme.fonts.secondaryBook};
  color: #fff;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
  }
`

const StyledCTA = styled(CTA)`
  span {
    font-family: ${props => props.theme.fonts.primaryMedium};
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    height: 40px;
    line-height: 40px;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

const CTAContainer = styled('div')`
  max-width: 240px;
  margin-top: 55px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 40px;
    max-width: 190px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 34px;
  }
`

const TextContainer = styled('div')`
  max-width: 587px;
  margin-left: auto;
  margin-top: 62px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 351px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 49px;
    margin-left: 0;
  }
`

const Title = styled(StyledTitle)`
  max-width: 676px;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 40px;
    line-height: 48px;
  }
`

export default function IntroBlock(props) {
  const { name } = props
  return (
    <Section>
      <Container>
        <Title textColor="#fff">
          Ready to take your product to the next level?
        </Title>
        <TextContainer>
          <Text>
            Talk to us to learn more about our promotional pricing only
            available to current {name} participating companies. We’d love to
            jump on a call to better understand how we can help you and what you
            are looking for. (Financing options available).
          </Text>
          <CTAContainer>
            <StyledCTA
              to="/contact/project-inquiries"
              onClick={() =>
                gtm(
                  'CTA Click',
                  'CTA Click',
                  'One Eleven',
                  'Request a meeting - Above footer'
                )
              }
            >
              <span>Request a meeting</span>
            </StyledCTA>
          </CTAContainer>
        </TextContainer>
      </Container>
    </Section>
  )
}

IntroBlock.propTypes = {
  name: PropTypes.string.isRequired,
}

import actoLogo from '../../../images/client-testimonials/acto.svg'
import cubicHealthLogo from '../../../images/client-testimonials/cubic.svg'
import clickaiLogo from '../../../images/client-testimonials/click-ai-logo.svg'

export const quotes = [
  {
    logo: {
      file: {
        url: cubicHealthLogo,
      },
      description: 'SmartTones Media Logo',
    },
    quote: 'They came through for us and we would recommend them to anyone',
    person: 'Felix Tsui',
    title: 'Project Manager, Cubic Health',
  },
  {
    logo: {
      file: {
        url: clickaiLogo,
      },
    },
    description: 'Clik.ai Logo',
    quote: 'Crowdlinker brought domain expertise and a fresh perspective',
    person: 'Parag Goswami',
    title: 'CEO and Co-founder, Clik.ai',
  },
  {
    logo: {
      file: {
        url: actoLogo,
      },
    },
    description: 'ACTO Logo',
    quote: 'We appreciate your team going above and beyond',
    person: 'Shannon Chen See',
    title: 'Content Marketing Coordinator at ACTO',
  },
]

export const testimonials = [
  {
    text:
      'We required a revamp of Facet program website, a new website, and a web application. Not only was Crowdlinker able to design, develop, QA, & deliver on all three products in 2.5 months, but they were detailed oriented and offered invaluable input. Our new websites are visually stunning and very functional. \n\nCrowdlinker assisted us with issues that arose even after the project was complete. They came through for us and we would recommend them to anyone in the future.',
    person: 'Felix Tsui',
    title: 'Cubic Health Inc. Project Manager',
  },
  {
    text:
      'Crowdlinker brought domain expertise and a fresh perspective to our project. They were well prepared, full of ideas and a pleasure to work with. Our experience working with Crowdlinker was positive. I recommend having a chat with them if you are a scaling company.',
    person: 'Parag Goswami',
    title: 'Clik.ai CEO and Co-founder',
  },
  {
    text:
      'Our entire team ‘outside of Marketing included’ has noticed our increased digital presence for the work that was done by Crowdlinker, and is very happy with the result. \n\nWe appreciate your team going above and beyond, and allocating additional time to this to make it possible in such a short timeframe.',
    person: 'Shannon Chen See',
    title: 'ACTO, Content Marketing Coordinator',
  },
]

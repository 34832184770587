import BackgroundImage from '../../../images/one-eleven/gradient-desktop@2x.jpg'
import BackgroundImageTablet from '../../../images/one-eleven/gradient-tablet.jpg'
import BackgroundImageTablet2x from '../../../images/one-eleven/gradient-tablet@2x.jpg'
import BackgroundImageMobile from '../../../images/one-eleven/gradient-mobile.jpg'
import BackgroundImageMobile2x from '../../../images/one-eleven/gradient-mobile@2x.jpg'
import DesktopPicture from '../../../images/one-eleven/how-were-different-desktop.png'
import DesktopPicture2x from '../../../images/one-eleven/how-were-different-desktop@2x.png'
import TabletPicture from '../../../images/one-eleven/how-were-different-tablet.png'
import TabletPicture2x from '../../../images/one-eleven/how-were-different-tablet@2x.png'
import MobilePicture from '../../../images/one-eleven/how-were-different-mobile.png'
import MobilePicture2x from '../../../images/one-eleven/how-were-different-mobile@2x.png'

export const backgroundImages = {
  desktop: BackgroundImage,
  tablet: BackgroundImageTablet,
  tablet2x: BackgroundImageTablet2x,
  mobile: BackgroundImageMobile,
  mobile2x: BackgroundImageMobile2x,
}

export const howWereDifferentImages = {
  desktopImage: DesktopPicture,
  desktopImage2x: DesktopPicture2x,
  tabletImage: TabletPicture,
  tabletImage2x: TabletPicture2x,
  mobileImage: MobilePicture,
  mobileImage2x: MobilePicture2x,
  alt: 'Images of team members meeting and working together',
}

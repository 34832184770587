import React from 'react'
import styled from '@emotion/styled'
import { useEffect } from 'react'

const StyledParagraphContainer = styled('div')`
  opacity: 0;
  transition: opacity 0.3s ease-in;

  &.active {
    opacity: 1;
  }
`
const StyledParagraph = styled('p')`
  width: 100%;
  font-size: 30px;
  text-align: left;
  line-height: 34px;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.secondaryBook};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    line-height: 27px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 20px;
  }

  span {
    display: block;

    &:first-of-type {
      font-weight: bold;
    }
  }
`

export default function BasicTestimonials({ testimonials, activeIndex }) {
  useEffect(() => {}, [activeIndex])

  return testimonials.map((testimonial, i) => (
    <StyledParagraphContainer
      key={i}
      className={i === activeIndex ? 'active' : ''}
    >
      <StyledParagraph
        style={{ display: i === activeIndex ? 'block' : 'none' }}
      >
        {testimonial.text}
      </StyledParagraph>
      <StyledParagraph
        style={{ display: i === activeIndex ? 'block' : 'none' }}
      >
        <span>{testimonial.person}</span>
        <span>{testimonial.title}</span>
      </StyledParagraph>
    </StyledParagraphContainer>
  ))
}

import React from 'react'
import styled from '@emotion/styled'
import BackgroundContainer from '../BackgroundContainer/BackgroundContainer'

const HeroContainer = styled('div')`
  pointer-events: none;
  height: 100%;
  position: relative;
  background-color: #fff;
`

const BackgroundImage = styled('div')`
    position: relative;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('${props => props.heroBg}');
    transition: opacity 0.3s ease;
    height: 100%;
    z-index: 1;
    min-height: 100vh;

    @media screen and (max-width: ${props => props.theme.screenWidth.large}) { 
      background-image: url('${props => props.heroBgTablet}');
    }

    // Retina media query for image
    @media 
    (-webkit-min-device-pixel-ratio: 2) and (max-width: ${props =>
      props.theme.screenWidth.large}) {
      background-image: url('${props => props.heroBgTablet2x}');
    }

    @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
      background-image: url('${props => props.heroBgMobile}');
    }

    // Retina media query for image
    @media 
    (-webkit-min-device-pixel-ratio: 2) and (max-width: ${props =>
      props.theme.screenWidth.small}) {
      background-image: url('${props => props.heroBgMobile2x}');
    }
`

const SVGLogoContainer = styled('div')`
  width: 2645px;
  top: 50%;
  right: -1700px;
  transform: translate(0, -50%);
  font-size: 0;
  position: absolute;
  overflow: hidden;
  fill: white;
  z-index: 1;
  position: absolute;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    width: 1530px;
    right: auto;
    left: 255px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    width: 1104px;
    left: 10px;
  }

  svg {
    pointer-events: none;
  }
`

export default function HeroAnimation(props) {
  const {
    heroBg,
    heroBgTablet,
    heroBgTablet2x,
    heroBgMobile,
    heroBgMobile2x,
  } = props

  return (
    <BackgroundContainer backgroundColor="#fff">
      <HeroContainer>
        <BackgroundImage
          heroBg={heroBg}
          heroBgTablet={heroBgTablet}
          heroBgTablet2x={heroBgTablet2x}
          heroBgMobile={heroBgMobile}
          heroBgMobile2x={heroBgMobile2x}
        />
        <SVGLogoContainer>
          <svg
            enableBackground="new 0 0 183 35"
            version="1.1"
            viewBox="0 0 183 35"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="st0"
              d="m90.4 29.5c0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5v-23.3c0-0.2-0.1-0.4-0.2-0.5-0.2-0.1-0.3-0.2-0.5-0.2h-2.5c-0.2 0-0.3 0.1-0.4 0.1-0.1 0.2-0.2 0.3-0.2 0.5v9.5l-1.5-0.9c-1-0.5-2.1-0.7-3.3-0.7-1.3 0-2.4 0.2-3.5 0.6-1 0.4-2 1-2.7 1.7s-1.3 1.6-1.7 2.6-0.6 2.1-0.6 3.3 0.2 2.3 0.6 3.3 1 1.9 1.6 2.7c0.7 0.7 1.6 1.3 2.6 1.7s2.1 0.6 3.2 0.6c1.5 0 2.8-0.3 3.9-0.9 0.5-0.2 0.9-0.5 1.3-0.9v0.8c0 0.2 0.1 0.4 0.2 0.5zm-0.5-5.8c-0.2 0.6-0.5 1.1-0.9 1.5-0.5 0.4-1 0.8-1.5 1-0.5 0.3-1.2 0.4-1.9 0.4s-1.3-0.2-1.9-0.4-1.1-0.6-1.5-1-0.7-0.9-0.9-1.5-0.3-1.3-0.3-1.9c0-0.7 0.1-1.3 0.3-1.9 0.4-1.1 1.3-2 2.4-2.5 0.5-0.3 1.2-0.4 1.9-0.4s1.4 0.2 1.9 0.4c0.6 0.2 1.1 0.6 1.5 1s0.7 0.9 0.9 1.5 0.3 1.2 0.3 1.9-0.1 1.3-0.3 1.9z"
            />
            <path
              className="st0"
              d="m56.8 29.6h2.6c0.3 0 0.5-0.1 0.6-0.4l3.2-9.4 3.6 9.5c0.1 0.2 0.3 0.4 0.6 0.4h2.5c0.3 0 0.5-0.1 0.6-0.4l4.8-14.5c0.1-0.2 0.1-0.4-0.3-0.7-0.1-0.2-0.3-0.3-0.5-0.3h-2.7c-0.3 0-0.5 0.2-0.6 0.5l-2.8 9.3-3.3-9.3c-0.1-0.2-0.3-0.4-0.6-0.4h-2.6c-0.3 0-0.5 0.1-0.6 0.4l-3.2 9.3-2.8-9.3c-0.1-0.3-0.3-0.5-0.6-0.5h-2.7c-0.2 0-0.4 0.1-0.5 0.3s-0.2 0.4-0.1 0.6l4.8 14.5c0.1 0.2 0.3 0.4 0.6 0.4z"
            />
            <path
              className="st0"
              d="m111.6 9.2c0-0.7-0.2-1.3-0.7-1.7-0.2-0.2-0.5-0.4-0.8-0.5s-0.7-0.2-1-0.2c-0.7 0-1.3 0.2-1.8 0.7-0.4 0.5-0.7 1.1-0.7 1.7s0.2 1.2 0.7 1.7 1.1 0.7 1.8 0.7 1.3-0.2 1.8-0.7 0.7-1.1 0.7-1.7z"
            />
            <path
              className="st0"
              d="m101.9 29.7c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5h0.1v-23.4c-0.1-0.3-0.3-0.6-0.7-0.6h-2.5c-0.2 0-0.4 0.1-0.6 0.1-0.1 0.2-0.2 0.3-0.2 0.5v23.4c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5z"
            />
            <path
              className="st0"
              d="m25.7 14.1c-0.2-0.1-0.3-0.2-0.5-0.2h-2.4c-0.2 0-0.4 0.1-0.5 0.2-0.1 0.2-0.2 0.3-0.2 0.5 0 0.5 0.1 1 0.1 1.6 0.1 0.6 0.1 1.2 0.1 1.8v11c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5v-7c0-1.6 0.3-2.8 0.9-3.6 0.3-0.4 0.7-0.7 1.1-0.9s1-0.3 1.7-0.3c0.4 0 0.7 0.1 1.1 0.2 0.2 0 0.4 0 0.6-0.1s0.3-0.3 0.3-0.5l0.1-2.5c0-0.2-0.1-0.5-0.4-0.6s-0.5-0.2-0.7-0.2h-0.6c-0.7 0-1.3 0.1-1.9 0.3s-1.1 0.4-1.6 0.7c-0.3 0.2-0.6 0.4-0.9 0.7v-0.6c0-0.2-0.1-0.4-0.2-0.5z"
            />
            <path
              className="st0"
              d="m9.8 18.3c0.3-0.4 0.8-0.8 1.3-1 0.5-0.3 1.1-0.4 1.9-0.4 0.7 0 1.3 0.2 1.8 0.4 0.5 0.3 1 0.6 1.5 1.2 0.2 0.3 0.6 0.3 0.9 0.1l2-1.7c0.3-0.2 0.3-0.6 0.1-0.9-0.7-0.8-1.7-1.5-2.8-1.9s-2.2-0.6-3.4-0.6-2.4 0.2-3.4 0.6-1.9 0.9-2.7 1.7c-0.7 0.7-1.3 1.6-1.7 2.6s-0.6 2.1-0.6 3.3 0.2 2.3 0.6 3.3 0.9 1.9 1.7 2.8c0.8 0.7 1.7 1.3 2.7 1.7s2.2 0.6 3.4 0.6c1.1 0 2.2-0.2 3.3-0.6s2-1 2.8-1.9c0.3-0.3 0.3-0.7 0-0.9l-1.8-1.7c-0.3-0.3-0.7-0.3-1 0-0.4 0.5-0.9 0.8-1.4 1.1s-1.2 0.4-1.9 0.4-1.3-0.2-1.8-0.4c-0.6-0.2-1-0.6-1.4-1s-0.7-0.9-0.9-1.5-0.3-1.2-0.3-1.9 0.1-1.3 0.3-1.9 0.4-1.1 0.8-1.5z"
            />
            <path
              className="st0"
              d="m35.2 27.7c0.7 0.7 1.6 1.3 2.7 1.7 1 0.4 2.2 0.6 3.4 0.6s2.3-0.2 3.4-0.6c1-0.4 1.9-1 2.7-1.7s1.4-1.6 1.8-2.6 0.6-2.1 0.7-3.3c0-1.2-0.3-2.3-0.7-3.3s-1-1.9-1.8-2.6c-0.7-0.7-1.7-1.3-2.7-1.7s-2.2-0.6-3.4-0.6-2.3 0.2-3.4 0.6c-1 0.4-1.9 1-2.7 1.7s-1.4 1.6-1.8 2.6c-0.5 1-0.7 2.1-0.7 3.3s0.3 2.3 0.7 3.3 1 1.9 1.8 2.6zm1.7-7.8c0.2-0.6 0.5-1.1 0.9-1.5 0.5-0.4 1-0.8 1.5-1 0.5-0.3 1.2-0.4 1.9-0.4s1.3 0.2 1.9 0.4 1.1 0.6 1.5 1 0.7 0.9 0.9 1.5 0.3 1.2 0.3 1.9-0.1 1.3-0.3 1.9-0.5 1.1-0.9 1.5c-0.5 0.4-1 0.8-1.5 1-0.5 0.3-1.2 0.4-1.9 0.4s-1.3-0.2-1.9-0.4-1.1-0.6-1.5-1-0.7-0.9-0.9-1.5-0.3-1.3-0.3-1.9c0-0.7 0.1-1.3 0.3-1.9z"
            />
            <path
              className="st0"
              d="m107.7 13.9c-0.2 0-0.4 0.1-0.5 0.2-0.1 0.2-0.2 0.3-0.2 0.5v14.4c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5v-14.4c0-0.2-0.1-0.4-0.2-0.5-0.2-0.1-0.3-0.2-0.5-0.2h-2.5z"
            />
            <path
              className="st0"
              d="m164.9 18.3c-0.4-1-0.9-1.9-1.6-2.6s-1.6-1.2-2.5-1.6-2-0.6-3.1-0.6c-1.2 0-2.3 0.2-3.3 0.6-0.9 0.4-1.8 1-2.6 1.7-0.7 0.7-1.3 1.6-1.7 2.6s-0.6 2.1-0.6 3.3c0 1.1 0.2 2.2 0.6 3.2 0.3 1 0.9 1.8 1.6 2.6s1.6 1.4 2.6 1.8c1 0.5 2.2 0.7 3.4 0.7 1.5 0 2.8-0.2 4-0.7 0.6-0.2 1.1-0.5 1.5-0.8 0.5-0.3 0.8-0.7 1.3-1.2 0.3-0.3 0.3-0.7 0-0.9h-0.1l-1.9-1.4c-0.3-0.2-0.7-0.1-0.9 0.1-0.4 0.3-0.6 0.5-0.7 0.6s-0.3 0.2-0.7 0.4c-0.6 0.3-1.4 0.5-2.4 0.5-0.5 0-1.1-0.1-1.6-0.3s-0.9-0.5-1.3-0.9-0.7-0.8-0.9-1.3l-0.3-0.9h11.3c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5h-0.3v-0.8c0-1.2-0.1-2.4-0.5-3.4zm-11.2 1.5c0.2-0.7 0.6-1.3 1.2-1.8 0.7-0.7 1.6-1 2.9-1 0.6 0 1.1 0 1.6 0.2s0.9 0.4 1.1 0.7c0.5 0.5 0.9 1.1 1 1.9h-7.8z"
            />
            <path
              className="st0"
              d="m177.9 13.8c-0.3-0.1-0.5-0.2-0.7-0.2s-0.4 0-0.6 0c-1.2-0.1-2.4 0.3-3.5 0.9-0.3 0.2-0.6 0.4-0.9 0.7v-0.6c0-0.2-0.1-0.4-0.2-0.5-0.2-0.1-0.3-0.2-0.5-0.2h-2.4c-0.2 0-0.4 0.1-0.5 0.2-0.1 0.2-0.2 0.3-0.2 0.5 0 0.5 0.1 1 0.1 1.6 0.1 0.6 0.1 1.2 0.1 1.8v11c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5v-7c0-1.6 0.3-2.8 0.9-3.6 0.3-0.4 0.7-0.7 1.1-0.9s1-0.3 1.7-0.3c0.4 0 0.7 0.1 1.1 0.2 0.2 0 0.4 0 0.6-0.1s0.3-0.3 0.3-0.5l0.1-2.4c0-0.2-0.1-0.5-0.4-0.6z"
            />
            <path
              className="st0"
              d="m137.4 5.1c-0.2-0.1-0.3-0.2-0.5-0.2h-2.5c-0.2 0-0.4 0.1-0.5 0.2-0.1 0.2-0.2 0.3-0.2 0.5v23.4c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5v-6l6.3 6.4c0.1 0.1 0.3 0.2 0.5 0.2h3.7c0.3 0 0.5-0.2 0.6-0.4s0.1-0.5-0.2-0.8l-7.5-7.4 6.7-6.1c0.2-0.2 0.3-0.5 0.2-0.7s-0.3-0.4-0.6-0.4h-3.5c-0.2 0-0.4 0.1-0.5 0.2l-5.7 5.3v-13.7c0-0.2-0.1-0.4-0.2-0.5z"
            />
            <path
              className="st0"
              d="m118.3 13.8c-0.2-0.1-0.3-0.2-0.5-0.2h-2.4c-0.2 0-0.4 0.1-0.5 0.2-0.1 0.2-0.2 0.3-0.2 0.5 0 0.5 0.1 1 0.1 1.6 0.1 0.6 0.1 1.2 0.1 1.8v11c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5v-7c0-0.8 0-1.5 0.2-2.1 0.1-0.6 0.4-1.1 0.7-1.5s0.7-0.7 1.2-0.9 1.1-0.3 1.8-0.3c0.5 0 1 0.1 1.3 0.2s0.6 0.3 0.8 0.6c0.4 0.6 0.7 1.4 0.7 2.5v8.7c0 0.2 0.1 0.4 0.2 0.5 0.2 0.1 0.3 0.2 0.5 0.2h2.5c0.2 0 0.4-0.1 0.5-0.2 0.1-0.2 0.2-0.3 0.2-0.5h-0.1v-9.1c0-0.9-0.2-1.8-0.4-2.6-0.3-0.8-0.7-1.5-1.2-2.1s-1.2-1.1-2-1.4-1.8-0.5-2.8-0.5c-0.7 0-1.3 0.1-1.9 0.3s-1.1 0.4-1.6 0.7c-0.3 0.2-0.6 0.4-0.9 0.7v-0.6s-0.1-0.4-0.2-0.5z"
            />
          </svg>
        </SVGLogoContainer>
      </HeroContainer>
    </BackgroundContainer>
  )
}
